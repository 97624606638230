
import { defineComponent, ref, reactive, toRefs, onMounted, nextTick, computed } from 'vue';
import { LeftOutlined } from '@ant-design/icons-vue';
import {
  getPicBasic,
  approveSIPOInformation,
  rejectInSIPOformation,
  getDealerInfo,
  getTDTAOverview,
  getProgramInfo
} from '@/API/delayCallback/index';
import downloadFile from '@/utils/claim/downloadFile';
import NoticeModal from '@/components/NoticeModal.vue';
import { useRouter } from 'vue-router';
import base64toBlob from '@/utils/rv/base64toBlob';
import MoreInfo from './compontents/moreInfo.vue';
import { message } from 'ant-design-vue';
const columns = [
  { title: '', dataIndex: 'taName', width: 160 },
  { title: '零售数量', dataIndex: 'retailCount', width: 100 },
  { title: '零售指标', dataIndex: 'retailTarget', width: 100 },
  { title: '零售完成率', dataIndex: 'completeSincere', width: 100 },
  {
    title: '撤回影响',
    dataIndex: 'withdrawInfluence',
    width: 100,
    slots: { customRender: 'withdrawInfluence' }
  }
];
const tableWidth = 560;
const tableHeight = 'clac(100vh - 120px)';
export default defineComponent({
  components: {
    LeftOutlined,
    NoticeModal,
    MoreInfo
  },
  setup(props, context) {
    const activeKey = ref<string>('1');
    const info = ref<object>({});
    const infoData = sessionStorage.getItem('DCBSIPOCheckDetailInfo');
    if (infoData) {
      info.value = JSON.parse(infoData);
    }
    const router = useRouter();
    //弹窗显示
    const modalVisible = ref<boolean>(false);
    const modalData = reactive({});
    const modalParams = reactive({
      noticeType: '',
      content: '',
      btnVisible: false
    });
    const isMoreVisible = ref<boolean>(false);
    const moreInfo = ref<any>({});
    const quarterTableData = ref([]);
    const monthTableData = ref([]);
    // 发票/行驶证/证明 查看弹窗
    const pictureModal = reactive({
      pictureModalFlag: false,
      pictureModalTitle: '',
      pictureModalImg: '',
      fileName: '',
      pdf: '',
      base64Blob: {},
      isImage: true
    });

    const backTop = () => {
      sessionStorage.setItem('DCBSIPOCheckDetailInfo', '');
      router.push({ path: '/delayCallback/SIPOCheckRetail' });
    };

    const getOverview = () => {
      const params = {
        dealerCode: (info.value as any).dealerCode,
        vinNo: (info.value as any).vinNo
      };
      getTDTAOverview(params).then((res: any) => {
        if (res.monthOverViews) {
          monthTableData.value = res.monthOverViews;
        } else {
          monthTableData.value = [];
        }
        if (res.quarterOverViews) {
          quarterTableData.value = res.quarterOverViews;
        } else {
          quarterTableData.value = [];
        }
      });
    };
    getOverview();

    const viewPicEvent = (vinNo: string, type: string, dealerCode: string) => {
      if (type == 'license') {
        pictureModal.pictureModalTitle = 'RE_' + vinNo;
      } else if (type == 'invoice') {
        pictureModal.pictureModalTitle = 'IN_' + vinNo;
      } else if (type == 'prove') {
        pictureModal.pictureModalTitle = '延期证明_' + vinNo;
      } else if (type === 'relationProof') {
        pictureModal.pictureModalTitle = 'RP_' + vinNo;
      }
      const params = { vinNo, type, dealerCode };
      getPicBasic({ params }).then((res: any) => {
        if (!res) {
          message.error('数据为空');
          return;
        }
        let URL = {};
        pictureModal.fileName = res.docName;
        const fileName = res.docName;
        const fileUrl = res.base64;
        if (fileName.indexOf('.docx') > -1) {
          pictureModal.isImage = false;
          URL = base64toBlob(fileUrl, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
          pictureModal.pdf = window.URL.createObjectURL(URL);
        } else if (fileName.indexOf('.xlsx') > -1) {
          pictureModal.isImage = false;
          URL = base64toBlob(fileUrl, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
          pictureModal.pdf = window.URL.createObjectURL(URL);
        } else if (fileName.indexOf('.jpg') > -1) {
          pictureModal.isImage = true;
          pictureModal.pictureModalImg = 'data:image/jpeg;base64,' + res.base64;
          URL = base64toBlob(fileUrl, 'image/jpeg');
        } else if (fileName.indexOf('.pdf') > -1) {
          pictureModal.isImage = false;
          URL = base64toBlob(fileUrl, 'application/pdf');
          pictureModal.pdf = window.URL.createObjectURL(URL);
        }
        pictureModal.pictureModalFlag = true;
        pictureModal.base64Blob = URL;
      });
    };

    const downloadImg = (pictureModal: any) => {
      const downloadElement = document.createElement('a');
      downloadElement.href = window.URL.createObjectURL(pictureModal.base64Blob);
      downloadElement.download = pictureModal.fileName;
      downloadElement.click();
    };

    const closePictureModal = () => {
      pictureModal.pictureModalFlag = false;
    };

    const openInfo = () => {
      getDealerInfo((info.value as any).dealerCode).then((res: any) => {
        isMoreVisible.value = true;
        moreInfo.value = res[0].dealerInfo;
      });
    };

    const reject = () => {
      const ids = ref<any>([]);
      ids.value.push((info.value as any).id);
      const params = {
        ids: ids.value,
        reason: ''
      };
      if ((info.value as any).status !== '2') {
        modalVisible.value = true;
        modalParams.noticeType = 'warning';
        modalParams.content = 'The application in this status can’t be operated.';
        modalParams.btnVisible = false;
        Object.assign(modalData, modalParams);
      } else {
        rejectInSIPOformation(params).then((res: any) => {
          if (res.code !== '0') {
            modalParams.noticeType = 'warning';
            // sessionStorage.setItem("muchContent", JSON.stringify(res.data));
            modalParams.content = res.message;
            modalParams.btnVisible = false;
          } else {
            modalParams.noticeType = 'success';
            modalParams.content = 'SIPO reject';
            modalParams.btnVisible = false;
          }
          modalVisible.value = true;
          Object.assign(modalData, modalParams);
        });
      }
    };

    const approve = () => {
      const ids = ref<any>([]);
      ids.value.push((info.value as any).id);
      const params = {
        ids: ids.value
      };
      if ((info.value as any).status !== '2') {
        modalVisible.value = true;
        modalParams.noticeType = 'warning';
        modalParams.content = 'The application in this status can’t be operated.';
        modalParams.btnVisible = false;
        Object.assign(modalData, modalParams);
      } else {
        approveSIPOInformation(params).then((res: any) => {
          if (res.code !== '0') {
            modalParams.noticeType = 'warning';
            // sessionStorage.setItem("muchContent", JSON.stringify(res.data));
            modalParams.content = res.message;
            modalParams.btnVisible = false;
          } else {
            modalParams.noticeType = 'success';
            modalParams.content = 'SIPO approved';
            modalParams.btnVisible = false;
          }
          modalVisible.value = true;
          Object.assign(modalData, modalParams);
        });
      }
    };
    // 关闭信息弹窗
    const closeNoticeModal = (param: { isVisible: boolean; continue: boolean }) => {
      modalVisible.value = param.isVisible;
    };

    const closeMore = (param: { isVisible: boolean }) => {
      isMoreVisible.value = param.isVisible;
    };

    const programColumns = [
      { title: 'Program Code', dataIndex: 'programCode', width: 160 },
      { title: 'Status', dataIndex: 'status', width: 100 }
    ];

    const programData = ref([]);

    const getProgramData = () => {
      const params = {
        dealerCode: (info.value as any).dealerCode,
        vinNo: (info.value as any).vinNo
      };
      getProgramInfo(params).then((res) => {
        programData.value = res;
      });
    };

    getProgramData();

    return {
      columns,
      tableWidth,
      tableHeight,
      quarterTableData,
      monthTableData,
      activeKey,
      info,
      pictureModal,
      viewPicEvent,
      downloadImg,
      closePictureModal,
      modalVisible,
      modalData,
      modalParams,
      closeNoticeModal,
      openInfo,
      reject,
      approve,
      isMoreVisible,
      closeMore,
      backTop,
      moreInfo,
      getOverview,
      programColumns,
      programData
    };
  }
});
