
import { defineComponent } from 'vue';

export default defineComponent({
    components: {},
    emits: ['close-more-visible', 'get-modal-info'],
    props: {
        isMoreVisible: {
            type: Boolean,
            required: true,
            default: false,
        },
        moreInfo: {
            type: Object,
            required: true,
            default() {
                return {};
            },
        },
    },
    setup(props, context) {
        // 获取相关数据
        const modal = (props as any).isMoreVisible;
        const info = (props as any).moreInfo;

        const close = () => {
            context.emit('close-more-visible', { isVisible: false });
        };

        return {
            modal,
            info,
            close,
        };
    },
});
